.main-pay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.pay-landing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.pay-landing-intro{
    background: url(./images/pay.jpg) fixed 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300%;
    padding-top: 10%;
    padding-bottom: 1%;
    margin-top: 0px;
}

.pay-intro-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    margin: 20px auto;
    padding: 25px 30px;
    width: 350px;
}

.pay-intro-body h1 {
    display: flex;
    text-align: center;
    color: #FFBA08;
    font-size: 55px;
    font-weight: bolder;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

.pay-intro-body p {
    display: flex;
    font-size: 25px;
    font-weight: 500;
    width: 350px;
    padding: 15px;
    margin-top: 15px;
    color: #FFBA08;
    font-family: 'Lumanosimo', cursive;
}
.pay-intro-body button {
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 500;
    margin-top: 10;
    width: 220px;
}

@media screen and (max-width:750px) {
    .pay-intro-body h1 {
        font-size: 35px;
        font-weight: 700;
    }

    .pay-intro-body p {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        width: 90%;
    }
}

@media screen and (max-width:350px) {
    .pay-intro-body h1 {
        font-size: 25px;
        font-weight: 500;
    }

    .pay-intro-body p {
        font-size: 15px;
        font-weight: 400;
        width: 90%;
    }
}
.main-pay h2{
    color: #03071E;
    font-size: 40px;
    font-weight: 900;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.main-form{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.form-side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 20px;
    padding: 20px 30px;
    flex: 1 1 250px;
}
.form-side h3{
    color: #03071E;
    margin: 50px auto;
    font-size: 35px;
    font-weight: 900;
    font-family: 'Lumanosimo', cursive;
}
.form-side .input-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    width: 100%;
}
.form-side .input-box img{
    width: 100%;
    height: 50px;
}
.form-side .input-box label{
    color: #03071E;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    margin-bottom: 5px;
}
.form-side .input-box input{
    width: 100%;
    border: 0px solid;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.1);
}
.main-form button{
    width: 70%;
    margin: 50px auto;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
}