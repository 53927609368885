.home-landing{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}
.landing-intro{
    background: url(./images/main.jpg) fixed 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300%;
    padding-top: 10%;
    padding-bottom: 1%;
    margin-top: 0px;
}
.master-intro_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 60%;
    color:#FFBA08 ;
}
.master-intro_body h1{
    position: relative;
    width: 300px;
    text-align: center;
    color: #FFBA08;
    font-size: 55px;
    font-weight: bolder;
    margin: 20px 15px;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.master-intro_body .intro-letter{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
    color: #FFBA08;
    width: 70%;
    margin-bottom: 13px;
}

.home-pages{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 50px auto;
    overflow: hidden;
}
.home-pages a{
    text-decoration: none;
}
.home-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.home-header h1{
    display: flex;
    font-size: 30px;
    font-weight: 900;
    font-family: 'Lumanosimo', cursive;
    color: #03071E;
    text-transform: capitalize;
}
.home-header p{
    display: flex;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Lumanosimo', cursive;
    color: #03071E;
    text-transform: capitalize;
}
.home-pages .page-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 400px;
    height: 600px;
    margin: 30px 30px;
    padding: 25px 20px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}
.home-pages .page-link:hover{
    transform: translateY(-10px);
}
.home-pages .page-link img{
    display: flex;
    width: 100%;
    height: 330px;
    border-radius: 2px;
    margin-bottom: 10px;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
}
.home-pages .page-link h1{
    display: flex;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color:#03071E ;
    text-transform: capitalize;
}
@media screen and (max-width:350px){
    .home-pages .page-link{
        width: 270px;
    }
}
.home-break{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 30vh;
    overflow: hidden;
}
.home-break-intro{
    background: url(./images/main.jpg) fixed 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300%;
    padding-top: 10%;
    padding-bottom: 1%;
    margin-top: 0px;
}

.home-skills{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px auto;
}

.home-skills .home-tool{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 400px;
    height: 500px;
    margin: 30px 30px;
    padding: 25px 20px;
    cursor: pointer;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
}
.home-skills .home-tool:hover {
    transform: translateY(-10px);
}
.home-skills .home-tool .icons{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 35px;
    background-color: #03071E;
}
.home-skills .home-tool .icons i{
    color: #F6C445;
    font-size: 65px;
    font-weight: bolder;
}
.home-skills .home-tool h1{
    color: #03071E;
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}


.home-slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.home-slider h1 {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color: #03071E;
    text-transform: capitalize;
}
.home-footer{
    background: url(./images/dessert\ \(8\).jpg) fixed 100% ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
}

.home-footer button{
    width: 200px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 900;
    font-family: 'Lumanosimo', cursive;
}
