.ice-landing {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 700px;
    background-color: #03071ea7;
}

.ice-landing .image-back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.ice-landing .landing-para {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    padding: 25px 30px;
    width: 90%;
}

.ice-landing .landing-para h1 {
    color: white;
    font-size: 55px;
    font-weight: bolder;
    margin: 20px 0;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    position: relative;
    transition: var(--main-transition);
}

.ice-landing .landing-para h1:hover {
    -webkit-text-stroke: 2px white;
    color: transparent;
}

.ice-landing .landing-para h1::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 150px;
    margin-top: 15px;
    left: 1px;
    bottom: -15px;
    border-bottom: 2px solid #FAA307;
    background-color: #FAA307;
}

.ice-landing .landing-para p {
    display: flex;
    font-size: 25px;
    font-weight: 500;
    width: 350px;
    margin: 20px auto;
    color: white;
    font-family: 'Lumanosimo', cursive;
}

@media screen and (max-width:750px) {
    .ice-landing .landing-para h1 {
        font-size: 35px;
        font-weight: 700;
    }

    .ice-landing .landing-para p {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        width: 90%;
    }
}

@media screen and (max-width:350px) {
    .ice-landing .landing-para h1 {
        font-size: 25px;
        font-weight: 500;
    }

    .ice-landing .landing-para p {
        font-size: 15px;
        font-weight: 400;
        width: 90%;
    }
}