.about-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: var(--main-background);
}
.about-main .about-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 20px auto;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
}
.timeline-image{
    display: flex;
    flex: 1;
}
.timeline-image img{
    width: 200px;
    height: 200px;
    border: 5px solid #FAA307;
    border-radius: 50%;
}
.timeline-panel , .timeline-heading , .timeline-body{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
    text-align: left;
    line-height: 1.5;
}
.timeline-story{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: #FAA307;
    border: 5px solid #03071E;
}